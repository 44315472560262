import Image from 'next/legacy/image';
import {
  Box,
  Heading,
  VStack,
  Text,
  Center,
  Flex,
  Button,
  Input,
  Stack
} from '@chakra-ui/react';
import { LayoutContainer } from '@components/common';
import React, { useState } from 'react';
import { analytics } from 'pages';
import { rudderTrack } from '@hooks/rudderTrack';

const FETCH_STATUS = {
  loading: 'loading',
  error: 'error',
  alreadySubscribed: 'subscribed',
  success: 'success'
} as const;

export type Keys = keyof typeof FETCH_STATUS;
export type FetchStatus = (typeof FETCH_STATUS)[Keys];

export function NewsLetter(): JSX.Element {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<FetchStatus>();

  const handleNewsletterSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (!email) return;

    setStatus(FETCH_STATUS.loading);

    try {
      const response = await fetch('/api/ortto-subscribe', {
        method: 'POST',
        // @ts-ignore no problem in operation, although type error appears.
        'Content-Type': 'application/json',
        body: JSON.stringify({
          email,
          tags: ['newsletter']
        })
      });

      if (!response.ok) {
        const parsedError = await response.json();

        if (parsedError.message?.includes('already subscribed')) {
          setStatus(FETCH_STATUS.alreadySubscribed);
        } else {
          throw new Error(parsedError);
        }
      } else {
        setEmail('');
        setStatus(FETCH_STATUS.success);
      }
    } catch (error) {
      // @ts-ignore no problem in operation, although type error appears.
      console.log('Error signing up for the newsletter: ', error.message);

      setStatus(FETCH_STATUS.error);
    }
  };

  return (
    <LayoutContainer verticalPadding="sm" pos="relative" maxW="100vw">
      <Center
        mx="auto"
        boxSize={{
          base: 16,
          md: 20
        }}
        mb={10}
        pos="relative"
      >
        <Image
          src="/images/clerk-logomark-square.svg"
          alt="Clerk's logo"
          layout="fill"
        />
      </Center>

      <Center>
        <Box
          borderRadius={{ lg: '2xl' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <VStack justifyContent="center">
            <Heading fontSize="2xl">Newsletter!</Heading>

            <Text pt={2} pb={4} textAlign="center">
              The latest news and updates from Clerk, sent to your inbox.
            </Text>
            {status === 'success' ? (
              <Box>
                <Heading fontSize="2xl">Thanks for signing up!</Heading>
              </Box>
            ) : (
              <Stack w="full">
                <form onSubmit={handleNewsletterSubmit}>
                  <Box
                    borderRadius="2xl"
                    border={{ base: '1px' }}
                    borderColor={{ base: 'transparent', sm: 'gray.200' }}
                    w="full"
                    bg="white"
                    zIndex={10}
                  >
                    <Flex
                      gap={4}
                      py={3}
                      px={{ sm: 3 }}
                      flexDir={{ base: 'column', sm: 'row' }}
                      borderRadius={{ base: '2xl' }}
                      bg="white"
                    >
                      <Input
                        w={{ base: 'full', lg: '280px' }}
                        placeholder="Your email address"
                        _placeholder={{ color: 'gray.400' }}
                        fontSize="md"
                        padding={4}
                        borderColor={{ base: 'gray.200', sm: 'transparent' }}
                        onChange={e => setEmail(e.target.value)}
                        _hover={{}}
                      />

                      <Button
                        type="submit"
                        variant="base"
                        w={{ base: 'full', sm: '146px' }}
                        borderRadius="lg"
                        bgColor="primary.500"
                        isLoading={status === 'loading'}
                        _loading={{
                          _hover: {
                            bg: 'primary.500'
                          }
                        }}
                        onClick={() => {
                          analytics.track(
                            'Marketing_Home_Newsletter Subscribe Button Clicked',
                            { surface: 'Marketing', location: 'Home' }
                          );
                          rudderTrack(
                            'Marketing_Home_Newsletter Subscribe Button Clicked',
                            { surface: 'Marketing', location: 'Home' }
                          );
                        }}
                      >
                        Subscribe
                      </Button>
                    </Flex>
                  </Box>
                </form>

                {status === 'subscribed' && (
                  <Box>
                    <Text>Email already subscribed.</Text>
                  </Box>
                )}
                {status === 'error' && (
                  <Box>
                    <Text color="red">
                      There was an issue while signing up.
                    </Text>
                  </Box>
                )}
              </Stack>
            )}
          </VStack>
        </Box>
      </Center>
    </LayoutContainer>
  );
}
